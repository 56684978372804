import { useEffect, useState } from "react";
import { FaShareAlt } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import PlayerTrackPageComponent from "../../components/player-track-page/player-track-page.component";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";
import { tryGetTrackByUserUidAndFileId } from "../../hooks/database-file";
import { useResetScroll } from "../../hooks/reset-scroll.hook";
import { ActiveSubscriptionTypes, UserTracks } from "../../types";
import { decodeString } from "../../utils/crypto-utils";
import { useTitle } from "../../hooks/useTitle.hook";

const TrackPage = () => {
  useTitle("Listen");
  const { user, databaseUser, subscriptionStatus } = useCombinedAuthHook();
  const { userId, fileId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [urlCleanUserId, setUrlCleanUserId] = useState(decodeString(userId));
  const [mp3Url] = useState(
    `${process.env.REACT_APP_FE_URL}/track/${userId}/${fileId}`
  );

  const [trackNotFound, setTrackNotFound] = useState(true);
  const [stateTrack, setTrack] = useState<UserTracks>();
  const [trackStartTime, setTrackStartTime] = useState(0);

  useResetScroll();

  useEffect(() => {
    if (userId && fileId) {
      tryGetTrackByUserUidAndFileId(urlCleanUserId, fileId).then(
        (track: UserTracks | null) => {
          if (!track) {
            setTrackNotFound(true);
            return;
          }

          setTrack(track!);
          setTrackNotFound(false);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (!stateTrack || !stateTrack.timestamps) return;

    const userTimestamp = stateTrack.timestamps[user.uid as any];

    if (!userTimestamp) return;

    setTrackStartTime(userTimestamp.activeTimestamp);
  }, [stateTrack, user.uid]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const isUserTheTrackOwner = urlCleanUserId === user.uid;

  // todo - name edit
  // const handleTrackNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  // setTrackNameState(e.target.value);
  // };

  // const handleTrackNameBlur = () => {
  //   setIsEditing(false);
  //   // Optionally, save the updated track name to the server here
  // };

  const handleShareClick = () => {
    navigator.clipboard.writeText(mp3Url);
    alert("URL copied to clipboard!");
  };

  return (
    <>
      {trackNotFound ? (
        <h2>Track not found.</h2>
      ) : (
        <>
          {!ActiveSubscriptionTypes.includes(subscriptionStatus) && (
            <div className="text-center py-4 lg:px-4">
              <div
                className="p-2 bg-blue-200 items-center text-gray-600 leading-none rounded-full flex lg:inline-flex shadow-sm px-5"
                role="alert"
              >
                <span className="mr-2 text-left flex-auto">
                  You can listen to your PDFs and share them with others now!{" "}
                  <Link className="underline" to="/app/pricing">
                    Try now!
                  </Link>
                </span>
              </div>
            </div>
          )}
          <div className="flex flex-col items-center h-fit md:h-screen sm:p-4">
            <div className="w-full h-[75%] bg-white rounded-lg shadow-md p-2 sm:p-6 md:flex md:flex-col md:justify-between">
              <div className="flex flex-col md:flex-row items-center mb-6 mt-6 gap-4">
                {/* <img
            src={artworkUrl}
            alt="Artwork"
            className="w-32 h-32 rounded-lg mb-4 md:mb-0 md:mr-6"
          /> */}
                <div className="flex-grow">
                  {/* {isEditing ? (
              <input
                type="text"
                value={trackName}
                onChange={handleTrackNameChange}
                onBlur={handleTrackNameBlur}
                className="text-2xl font-semibold border-b-2 border-gray-300 focus:outline-none focus:border-blue-500"
                autoFocus
              />
            ) : ( */}
                  {stateTrack!.model === "visuals" && (
                    <>
                      <p className="text-sm text-gray-500">Text+Visuals ✨</p>
                    </>
                  )}
                  <div className="flex items-center">
                    <h2 className="text-2xl font-semibold">
                      {stateTrack!.trackUid}
                    </h2>
                    {/* <button
                  onClick={handleEditClick}
                  className="ml-2 text-gray-600"
                >
                  <FaPen size={15} />
                </button> */}
                  </div>
                  {/* )} */}
                  <p className="text-gray-600 text-md">
                    Uploaded {isUserTheTrackOwner && "by you"} on{" "}
                    {stateTrack?.date}
                  </p>
                </div>
                {ActiveSubscriptionTypes.includes(subscriptionStatus) &&
                  isUserTheTrackOwner && (
                    <button
                      onClick={handleShareClick}
                      className="bg-orange-500 hover:bg-orange-600 text-white rounded-xl shadow-xl p-2 pl-4 pr-4"
                    >
                      Share
                      <FaShareAlt
                        className="inline ml-2"
                        color="#fff"
                        size={10}
                      />
                    </button>
                  )}
              </div>
              <div className="w-full flex items-center justify-center mt-16 relative">
                {stateTrack && (
                  <PlayerTrackPageComponent
                    author={urlCleanUserId}
                    trackUid={stateTrack.fileId}
                    url={stateTrack.recordingUrl}
                    startTime={trackStartTime}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TrackPage;
