import { createElement } from "react";
import { useResetScroll } from "../../hooks/reset-scroll.hook";
import { useTitle } from "../../hooks/useTitle.hook";

const PricingTablePage = () => {
  useTitle("Pricing");
  useResetScroll();
  
  return createElement("stripe-pricing-table", {
    "pricing-table-id": process.env.REACT_APP_PRICING_TABLE_ID,
    "publishable-key": process.env.REACT_APP_PRICING_TABLE_PUBLISHABLE_KEY,
  });
};

export default PricingTablePage;
