import { HiMiniQuestionMarkCircle } from "react-icons/hi2";

export type FaqProps = {
  question: string;
  children: string;
};

const Faq = ({ question, children }: FaqProps) => {
  return (
    <div>
      <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
        <HiMiniQuestionMarkCircle color="#3f83f8" className="mr-2" />
        {question}
      </h3>
      <p className="text-gray-500 dark:text-gray-400">{children}</p>
    </div>
  );
};

export default Faq;
