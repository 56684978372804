import { atom, selector } from "recoil";
import { FileStatusInfo } from "../types";

export const globalFileUploadCount = atom({
    key: 'globalFileUploadCount',
    default: 0,
});

export const globalFileUpload = atom({
    key: 'globalFileUpload',
    default: [] as FileStatusInfo[],
})

export const filesUnderProcess = selector({
    key: 'completedFileUploads',
    get: ({ get }) => {
        const files = get(globalFileUpload);
        return files.filter(file => file.status === 'processing');
    }
})