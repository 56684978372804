import { child, get, getDatabase, ref, set, update } from "firebase/database";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { authState } from "../state/auth.state";
import { DatabaseUserData } from "../types";

export const useDatabaseUser = () => {
  const [databaseUser, setDatabaseUser] = useState<DatabaseUserData>(
    {} as DatabaseUserData
  );
  const [loading, setLoading] = useState<boolean>(true);
  const userState = useRecoilValue(authState);
  const db = getDatabase();

  const createUserRecord = async () => {
    const userData: DatabaseUserData = {
      email: userState.email,
      name: userState.name,
      photo: userState.photo,
      stripeSessionId: "",
    };
    await set(ref(db, "users/" + userState.uid), userData);
    setDatabaseUser(userData);
  };

  const updateUserRecord = async (
    updates: Partial<DatabaseUserData>,
    userUid = ""
  ) => {
    try {
      const targetUserUid = !!userUid ? userUid : userState.uid;
      await update(ref(db, "users/" + targetUserUid), updates);
    } catch (e) {
      console.error("Error updating user record:", e);
    } finally {
      setLoading(false);
    }
  };

  const getDatabaseUser = async () => {
    const dbRef = ref(db);
    try {
      const snapshot = await get(child(dbRef, `users/${userState.uid}`));
      if (snapshot.exists()) {
        setDatabaseUser(snapshot.val());
        return snapshot.val();
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }

    return null;
  };

  useEffect(() => {
    if (!userState) {
      return;
    }

    getDatabaseUser().then((userDataSnapshot) => {
      if (!userDataSnapshot) {
        createUserRecord();
      }
    });
  }, [userState]);

  return {
    databaseUser: databaseUser,
    updateUser: updateUserRecord,
    loading: loading,
  };
};
