import CryptoJS from 'crypto-js';
import base64url from 'base64-url';

const secretKey = 'sample-salt-key';

export function encodeString(input: string): string {
    const ciphertext = CryptoJS.AES.encrypt(input, secretKey).toString();
    return base64url.escape(ciphertext);
}

export function decodeString(encoded: string | undefined): string {
    if (!encoded) return '';
    const unescaped = base64url.unescape(encoded);
    const bytes = CryptoJS.AES.decrypt(unescaped, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
}