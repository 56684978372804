import { signOut } from "firebase/auth";
import { useState } from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaUserCircle,
} from "react-icons/fa";
import { Link, Outlet } from "react-router-dom";
import { useRecoilState } from "recoil";
import { auth } from "../../firebase";
import { AuthUser, authState } from "../../state/auth.state";
import {
  navigateToAuthModalProp,
  showAuthModal,
} from "../../state/showAuthModal.state";
import AuthModal from "../auth-modal/auth-modal.component";
import "./layout.styles.scss";

const Layout = () => {
  const [authUser, setAuthUser] = useRecoilState(authState);
  const [showModal, setShowModal] = useRecoilState(showAuthModal);
  const [navigateToGlobalProp, setNavigateToGlobalProp] = useRecoilState(
    navigateToAuthModalProp
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const singOut = async () => {
    try {
      await signOut(auth);
      setAuthUser(undefined as never as AuthUser);
    } catch (error) {
      console.error("Error while signing out:", error);
    }
  };

  const toggleAuthModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <nav className="fixed top-6 left-1/2 transform -translate-x-1/2 bg-gradient-to-r from-sky-950 to-cyan-800	shadow-lg rounded-full px-6 py-3 flex items-center justify-between w-11/12 max-w-4xl z-10">
        <div className="flex items-center">
          <span className="font-semibold mr-4 text-white">ReadPDF.io</span>
        </div>
        <ul className="flex space-x-6">
          <li>
            <a href="/#features" className="text-gray-200 hover:text-blue-500">
              Features
            </a>
          </li>
          <li>
            <a href="/#pricing" className="text-gray-200 hover:text-blue-500">
              Pricing
            </a>
          </li>
        </ul>
        {!!authUser ? (
          <div
            className="inline cursor-pointer w-6 h-6 rounded-3xl"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            {!!authUser.photo ? (
              <div className=" w-6 h-6 overflow-hidden rounded-3xl">
                <img src={authUser.photo} alt="User photo" />
              </div>
            ) : (
              <div className=" w-6 h-6 rounded-3xl flex border justify-center">
                <FaUserCircle className="text-white text-2xl hover:text-gray-100" />
              </div>
            )}
            {dropdownOpen && (
              <div className="relative -left-28 top-2 mt-2 w-36 bg-cyan-800 rounded-md shadow-lg py-2 z-20">
                <Link
                  to={"app"}
                  className="block px-4 py-1 text-white hover:bg-cyan-900"
                >
                  My Library
                </Link>
                <a
                  onClick={singOut}
                  href="#"
                  className="block px-4 py-1 text-white hover:bg-cyan-900"
                >
                  Logout
                </a>
              </div>
            )}
          </div>
        ) : (
          <div
            className="flex items-center cursor-pointer"
            onClick={toggleAuthModal}
          >
            <FaUserCircle className="text-white text-2xl hover:text-gray-100" />
          </div>
        )}
      </nav>

      <main className="mx-auto pt-0">
        <Outlet />
      </main>

      <footer className="bg-gray-800 text-white py-12">
        <div className="container mx-auto px-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-6 md:mb-3">
              <a href="/" className="text-2xl font-bold text-white">
                ReadPDF.io
              </a>
            </div>
            <div className="flex space-x-6 mb-6 md:mb-5">
              <a href="/" className="hover:text-blue-500">
                Home
              </a>
              <Link to={"/privacy-policy"} className="hover:text-blue-500">
                Privacy Policy
              </Link>
              <Link
                to={"/terms-and-conditions"}
                className="hover:text-blue-500"
              >
                Terms and Conditions
              </Link>
            </div>
            <div className="flex space-x-6">
              <a
                href="https://www.facebook.com/profile.php?id=61563878062519"
                target="_blank"
                className="hover:text-blue-500"
              >
                <FaFacebook size={24} />
              </a>
            </div>
          </div>
          <div className="mt-8 text-center text-gray-400">
            <p>
              &copy; {new Date().getFullYear()} ReadPDF.io. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
      <AuthModal
        show={showModal}
        onClose={toggleAuthModal}
        navigateTo={navigateToGlobalProp}
      />
    </>
  );
};

export default Layout;
