import { useEffect, useRef, useState, useCallback } from "react";
import { useCombinedAuthHook } from "./combined-auth.hook";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  filesUnderProcess,
  globalFileUpload,
  globalFileUploadCount,
} from "../state/global-file-upload.state";
import { showFileConvertedModal } from "../state/showFileConverted.state";

export const useFilesStatusCheck = () => {
  const { user } = useCombinedAuthHook();
  const [fileUploadCount] = useRecoilState(globalFileUploadCount);
  const [files, setFiles] = useRecoilState(globalFileUpload);
  const filesInProgress = useRecoilValue(filesUnderProcess);
  const setShowFileConvertedModalState = useSetRecoilState(
    showFileConvertedModal
  );
  const [error, setError] = useState<Error | null>(null);
  const interval = useRef<NodeJS.Timer | null>(null);

  const getFileUnderConverstionRequest = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_APPLICATION_URL}/under-conversion`,
        {
          headers: {
            "user-id": user.uid,
          },
        }
      );
      const data = await response.json();
      if (data.files.length > 0) {
        if (data.files.length > files.length) {
          // new file is being converted
          setError(null);
        }
        setFiles(() => data?.files);
      }
    } catch (error) {
      handleError(error as Error);
    }
  }, [user?.uid]);

  const getFilesUnderConversionStatus = useCallback(async () => {
    if (filesInProgress.length === 0) {
      setFiles([]);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APPLICATION_URL}/files-info`,
        {
          headers: {
            "user-id": user.uid,
            "file-ids": filesInProgress.map((file) => file.fileId).join(","),
          },
        }
      );
      const data = await response.json();

      if (data?.files.some((x: any) => x.status === "processed")) {
        setShowFileConvertedModalState(true);
      }

      setFiles(data?.files ?? []);
    } catch (error) {
      handleError(error as Error);
    }
  }, [filesInProgress]);

  // Fetch initially files that are under conversion and wait for the trigger
  useEffect(() => {
    getFileUnderConverstionRequest();
  }, [fileUploadCount]);

  // Once there are files that are under conversion start polling for their status
  useEffect(() => {
    if (files.length > 0) {
      interval.current = setInterval(getFilesUnderConversionStatus, 5000);
    } else if (interval.current) {
      clearInterval(interval.current);
    }

    return () => {
      stopPolling();
    };
  }, [files]);

  const stopPolling = () => {
    if (interval.current) {
      clearInterval(interval.current);
    }
  };

  const handleError = (error: Error) => {
    console.error("Error fetching files under conversion", error);
    setError(error as Error);
    stopPolling();
  };

  return { filesUnderConversion: files, error };
};
