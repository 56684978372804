import { atom } from "recoil";

export type AuthUser = {
    name: string;
    email: string;
    uid: string;
    photo: string | null;
}

export const authState = atom({
    key: 'authState',
    default: undefined as never as AuthUser,
});