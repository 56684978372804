import * as amplitude from "@amplitude/analytics-browser";
import { BrowserOptions as AmplitudeAnalyticsOptions } from "@amplitude/analytics-types";
import { autocapturePlugin } from "@amplitude/plugin-autocapture-browser";
import { SessionReplayOptions } from "@amplitude/plugin-session-replay-browser/lib/esm/typings/session-replay";
import { plugin as sessionReplay } from "@amplitude/plugin-session-replay-browser";
import { useEffect } from "react";

const options: AmplitudeAnalyticsOptions = {
  // serverZone: "EU",
  defaultTracking: true,
};

const sessionReplayOptions: SessionReplayOptions = {
  sampleRate: 1,
};

const useAmplitudeAnalytics = () => {
  useEffect(() => {
    amplitude.init("920f9b5bbb3526633fc2642121e1536e", options);
    amplitude.add(sessionReplay(sessionReplayOptions));
    amplitude.add(autocapturePlugin());
  }, []);
};

export default useAmplitudeAnalytics;
