import { useEffect } from "react";
import { Link } from "react-router-dom";

type ModalProps = {
  show: boolean;
  onClose: () => void;
};

const UploadFinishedModal = ({ show, onClose }: ModalProps) => {
  const closeModal = () => {
    onClose();
  };

  useEffect(() => {
    const handleEscape = (event: any) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    if (show) {
      document.addEventListener("keydown", handleEscape);
    } else {
      document.removeEventListener("keydown", handleEscape);
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [show, onClose]);

  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[52]">
      <div className="bg-gradient-to-r from-yellow-50 to-yellow-100 text-gray-800 rounded-lg shadow-lg p-6 max-w-sm">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold mb-4"></h2>
          <button
            onClick={closeModal}
            className="text-gray-500 hover:text-gray-800 h-fit"
          >
            &times;
          </button>
        </div>
        <div className="p-10 rounded-lg text-center ">
          <h2 className="text-4xl font-bold mb-4">File ready 🎉</h2>
          <p className="text-xl mb-6">
            Your PDF has been converted into an audio file successfully! You can
            now listen it in your library.
          </p>
          <Link
            to="/app"
            onClick={closeModal}
            className="bg-yellow-900 text-white font-semibold py-3 px-6 rounded-full hover:bg-white-950 transition duration-300"
          >
            Go to Library
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UploadFinishedModal;
