import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { conversionModalState } from "../../state/conversion-model.state";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";
import { ActiveSubscriptionTypes } from "../../types";
import Badge, { BadgeSize } from "../badge/badge.component";

const UploadToggleModels = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] =
    useRecoilState(conversionModalState);

  const { subscriptionStatus, loading } = useCombinedAuthHook();

  const [hasActiveSubscription, setHasActiveSubscription] = useState(true);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (loading) return;
    setHasActiveSubscription(
      ActiveSubscriptionTypes.includes(subscriptionStatus)
    );
  }, [subscriptionStatus, loading]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const handleOptionChange = (option: string) => {
    if (hasActiveSubscription) {
      setSelectedOption(option);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        id="dropdownRadioHelperButton"
        onClick={toggleDropdown}
        className="text-black bg-white ring-1 ring-slate-400 hover:bg-slate-200 focus:ring-4 focus:outline-none focus:ring-slate-400 font-normal rounded-lg px-4 py-1.5 text-center inline-flex items-center shadow"
        type="button"
      >
        Extract {selectedOption === "default" ? "text only" : "text+visuals ✨"}
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="#6b7280"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          id="dropdownRadioHelper"
          className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-60 dark:bg-gray-700 dark:divide-gray-600 absolute mt-2"
        >
          <ul
            className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownRadioHelperButton"
          >
            <li>
              <div className="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                <div className="flex items-center h-5 cursor-pointer">
                  <input
                    id="helper-radio-4"
                    name="helper-radio"
                    type="radio"
                    value="default"
                    checked={selectedOption === "default"}
                    onClick={() => handleOptionChange("default")}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                </div>
                <div className="ms-2 text-sm">
                  <label
                    htmlFor="helper-radio-4"
                    className="font-medium text-gray-900 dark:text-gray-300"
                  >
                    <div>Text only</div>
                    <p
                      id="helper-radio-text-4"
                      className="text-xs font-normal text-gray-500 dark:text-gray-300"
                    >
                      This model ignores all visual content and would only read
                      you the text. Perfect for case-studies or documentation.
                      It's the fastest way to convert a PDF to a listenable
                      file.
                    </p>
                  </label>
                </div>
              </div>
            </li>
            <li className={!hasActiveSubscription ? "cursor-not-allowed" : ""}>
              <div
                className={`flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 ${
                  !hasActiveSubscription ? "cursor-not-allowed" : ""
                }`}
              >
                <div
                  className={`flex items-center h-5 ${
                    !hasActiveSubscription
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  }`}
                >
                  <input
                    id="helper-radio-5"
                    name="helper-radio"
                    type="radio"
                    value="visuals"
                    disabled={!hasActiveSubscription}
                    checked={selectedOption === "visuals"}
                    onClick={() => handleOptionChange("visuals")}
                    className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 ${
                      !hasActiveSubscription ? "cursor-not-allowed" : ""
                    }`}
                  />
                </div>
                <div className={`ms-2 text-sm`}>
                  <label
                    htmlFor="helper-radio-5"
                    className={`font-medium text-gray-900 dark:text-gray-300 ${
                      !hasActiveSubscription ? "cursor-not-allowed" : ""
                    }`}
                  >
                    <div>
                      Text+Visuals ✨{" "}
                      {!hasActiveSubscription ? (
                        <Badge size={BadgeSize.Small} bgColor="bg-slate-500">
                          Pro
                        </Badge>
                      ) : (
                        <Badge size={BadgeSize.Small} bgColor="bg-slate-500">
                          Beta
                        </Badge>
                      )}
                    </div>
                    <p
                      id="helper-radio-text-5"
                      className="text-xs font-normal text-gray-500 dark:text-gray-300"
                    >
                      This model reads out text, graphics, charts, tables,
                      formulas, etc. Slower than the text-only model. Currently
                      limited to 4 images/page, only describes visual
                      information in English.
                    </p>
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default UploadToggleModels;
