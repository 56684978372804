import { useEffect } from "react";
import { Link } from "react-router-dom";

type SubscribeModalProps = {
  show: boolean;
  onClose: () => void;
};

const SubscribeModal = ({ show, onClose }: SubscribeModalProps) => {
  const closeModal = () => {
    onClose();
  };

  useEffect(() => {
    const handleEscape = (event: any) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    if (show) {
      document.addEventListener("keydown", handleEscape);
    } else {
      document.removeEventListener("keydown", handleEscape);
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [show, onClose]);

  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[52]">
      <div className="bg-gradient-to-r from-yellow-400 to-orange-500 text-white rounded-lg shadow-lg p-6 max-w-sm">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold mb-4"></h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-800 h-fit"
          >
            &times;
          </button>
        </div>
        <div className="p-10 rounded-lg text-center ">
          <h2 className="text-4xl font-bold mb-4">Get full access now!</h2>
          <p className="text-xl mb-6">
            With no subscription, you could only listen to conversions from others.
            <br />
            Subscribe now for unlimited conversions, sharing with friends and much more!
            Perfect for on-the-go listening, study aids, and more!
          </p>
          <Link
            to={"/app/pricing"}
            className="bg-white text-gray-900 font-semibold py-3 px-6 rounded-full hover:bg-gray-100 transition duration-300"
          >
            Try for free!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SubscribeModal;
