import { useEffect, useState } from "react";
import SubscribeModal from "../../components/subscribe-modal/subscribe-modal.component";
import UploadContextMenu from "../../components/upload-context-menu/upload-context-menu.component";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";
import { useResetScroll } from "../../hooks/reset-scroll.hook";
import useFileUpload from "../../hooks/useFileUpload.hook";
import { ActiveSubscriptionTypes } from "../../types";
import "./upload.styles.scss";
import { useTitle } from "../../hooks/useTitle.hook";

const UploadPage = () => {
  useTitle("Upload");
  const { subscriptionStatus, loading } = useCombinedAuthHook();
  const [showModal, setShowModal] = useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(true);
  const {
    fileInputRef,
    progressBarRef,
    isDragging,
    setIsDragging,
    triggerFileUpload,
    handleFileChange,
    handleDragOver,
    handleDragLeave,
    handleDrop,
  } = useFileUpload();

  const [selectedPdfLanguage, setSelectedPdfLanguage] = useState("en");
  useResetScroll();

  useEffect(() => {
    if (!hasActiveSubscription) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [hasActiveSubscription]);

  useEffect(() => {
    if (loading) return;
    setHasActiveSubscription(
      ActiveSubscriptionTypes.includes(subscriptionStatus)
    );
  }, [subscriptionStatus, loading]);

  const authAnd = (
    evt: any,
    callback: (a: any, b?: any) => any,
    args?: any
  ) => {
    if (hasActiveSubscription) {
      callback(evt, args && args);
      return;
    }

    setShowModal(true);
  };

  return (
    <>
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 z-50 ${
          isDragging ? "" : "hidden"
        }`}
      ></div>
      <h1 className="bold text-center text-xl">Upload a PDF</h1>

      <div className="w-11/12 ml-5 mt-5">
        <UploadContextMenu
          uploadedCallback={(selectedLanguage) =>
            setSelectedPdfLanguage(selectedLanguage)
          }
        />
      </div>
      <div
        className="mb-9"
        onClick={(e) => authAnd(e, triggerFileUpload)}
        onDragOver={(e) => authAnd(e, handleDragOver)}
        onDragLeave={(e) => authAnd(e, handleDragLeave)}
        onDrop={(e) => handleDrop(e, selectedPdfLanguage)}
      >
        <div
          className={`dash-pattern w-11/12 h-[350px] border-2 border-dashed rounded-lg flex items-center justify-center m-8 p-8 cursor-pointer ${
            isDragging
              ? "relative z-[60] bg-gray-200 border-gray-500"
              : "bg-white hover:bg-[#fbfbfb] border-gray-300"
          } ${!hasActiveSubscription ? "cursor-not-allowed" : ""}`}
        >
          <h3 className="text-center text-gray-800 text-2xl">
            {isDragging
              ? "Drop here"
              : "Drag and Drop PDF Here or Click to Upload"}
          </h3>
          <input
            type="file"
            id="pdfUpload"
            className="hidden-input"
            accept=".pdf"
            ref={fileInputRef}
            onChange={(evt) =>
              authAnd(evt, handleFileChange, selectedPdfLanguage)
            }
          />
        </div>
      </div>
      <section
        id="progress-bar"
        className="text-center my-8"
        ref={progressBarRef}
        hidden
      >
        <div className="relative w-full h-6 bg-gray-200 rounded overflow-hidden">
          <div
            id="progress-bar-update"
            className="h-full bg-blue-500"
            style={{ width: "0%" }}
          >
            0%
          </div>
        </div>
        <span>Preparing your file for listening...</span>
      </section>

      <SubscribeModal
        show={showModal}
        onClose={() => setShowModal(!showModal)}
      />
    </>
  );
};

export default UploadPage;
