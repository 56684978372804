import { User } from "@firebase/auth";
import { useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { auth } from "../firebase";
import { authState, AuthUser } from "../state/auth.state";
import { useDatabaseUser } from "./database-user.hook";
import { subscriptionStatusState } from "../state/subscription-status.state";
import { signOut } from "firebase/auth";

export const useAuth = () => {
    const [userState, setUserState] = useRecoilState(authState);
    const [isLoading, setIsLoading] = useState(!!userState ? false: true);
    const resetSubscription = useResetRecoilState(subscriptionStatusState);

    useDatabaseUser();

    const logout = async () => {
        await signOut(auth);
        setUserState(undefined as never as AuthUser);
    }

    const handleUser = (user: User | null) => {
        if (user) {
            if (userState?.uid && userState.uid === user.uid) {
                return;
            }

            setUserState({
                email: user.email!,
                name: user.displayName!,
                photo: user.photoURL,
                uid: user.uid
            });
            
            resetSubscription();
        } else {
            setUserState(null as never);
            resetSubscription();
        }

        setIsLoading(false);
    };

    useEffect(() => {
        const unsubscribe = auth.onIdTokenChanged(handleUser);
        return () => unsubscribe();
    }, []);

    return {
        user: userState,
        logout: logout,
        isLoading
    };
};