import { useResetScroll } from "../../hooks/reset-scroll.hook";
import { useTitle } from "../../hooks/useTitle.hook";

const TermsAndConditionsPage = () => {
  useTitle("Terms and Conditions");
  useResetScroll();

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-10 mt-32">Terms of Service</h1>
      <p className="text-gray-700 mb-6">Last updated July 26, 2024</p>

      <section>
        <h2 className="text-xl font-semibold mb-2">1. Overview</h2>
        <p className="text-gray-700 mb-4">
          These Terms & Conditions (these “Terms”) contain the terms and
          conditions on which ReadPDF.io (“ReadPDF” or “we”) supplies content,
          products, or services listed on readpdf.io (the “Website”), through
          our mobile apps (the “Apps”), or via other delivery methods (including
          browser extensions, collectively known as “The Products”) to you, the
          user.
        </p>
        <p className="text-gray-700 mb-4">
          By using ReadPDF.io’s website, apps, browser extensions, or other
          products, you agree to be bound by these terms.
        </p>
        <p className="text-gray-700 mb-4">
          These terms constitute an agreement between you and ReadPDF. ReadPDF,
          not Apple nor Google, is solely responsible for the content in this
          app and related Products.
        </p>
        <p className="text-gray-700 mb-4">
          This is a non-transferable license to use the Products on desktop
          computers and mobile devices that you own. For Apple device users,
          this license is pursuant to Usage Rules set forth in the Apple Media
          Services Terms and Conditions, except that such Licensed Application
          may be accessed and used by other accounts associated with the
          purchaser.
        </p>
        <p className="text-gray-700 mb-4">
          The Products are meant for PERSONAL, NON-COMMERCIAL, INDIVIDUAL USE
          ONLY. You may only use The Products with material you’ve lawfully
          purchased, or whose rights you’ve lawfully acquired. This may include
          material you’ve purchased, material you’ve licensed through an
          educational institution, or copyright-free material. Sharing your
          ReadPDF account with others, or sharing content generated through The
          Products, is strictly prohibited and grounds for immediate termination
          of your account.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">2. Terms and Conditions</h2>
        <p className="text-gray-700 mb-4">
          These Terms lay out the complete agreement between you and ReadPDF,
          for the use of all ReadPDF Products.
        </p>
        <p className="text-gray-700 mb-4">
          As part of using ReadPDF, you are hereby consenting to the storage and
          processing of certain data. These include, but are not limited to:
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>Your name</li>
          <li>Your email address</li>
          <li>
            The list of articles, blog posts, and other content you wish to read
          </li>
          <li>List of products you've purchased</li>
          <li>Actions you've performed in the app</li>
        </ul>
        <p className="text-gray-700 mb-4">
          These terms may change at any time. We’ll notify users by updating the
          terms on our website. If you continue to use the Products, you hereby
          agree to be bound by the Terms as changed.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">3. Accounts</h2>
        <p className="text-gray-700 mb-4">
          By signing up on any of ReadPDF’s Products, you become a member and
          account holder of a ReadPDF account. You are solely responsible for
          keeping your email and password secure.
        </p>
        <p className="text-gray-700 mb-4">
          When you create an account on ReadPDF, you represent and warrant that
          you’re legally capable of entering into a contract, and that no
          applicable laws are violated by your use of the product.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">4. Subscriptions</h2>
        <p className="text-gray-700 mb-4">
          Upon signing up for a ReadPDF account, you will initially have an
          account which is on our Free Tier.
        </p>
        <p className="text-gray-700 mb-4">
          The Free Tier is a zero-cost account, with limited functionality and a
          limited number of reading hours.
        </p>
        <p className="text-gray-700 mb-4">
          You can upgrade to a subscription fee-based plan. Upon payment, this
          will unlock additional functionality. This may include additional
          hours, higher quality voices, or other additional features.
        </p>
        <p className="text-gray-700 mb-4">
          If you make a purchase through a mobile store, such as the Apple
          iTunes Store, Apple App Store, or Google Play Store, the purchase is
          final and cannot be refunded. If you purchase directly on our website,
          you may be able to contact support for a refund, provided the
          subscription has been active for 30 days or less.
        </p>
        <p className="text-gray-700 mb-4">
          To cancel a subscription, you can do so inside your ReadPDF.io
          account, or by contacting support@readpdf.io. Subscriptions created
          through a mobile app store, such as the Apple iTunes Store, Apple App
          Store, or Google Play Store, cannot be canceled by us. You must cancel
          these subscriptions using Apple and Google’s store interfaces,
          respectively.
        </p>
        <p className="text-gray-700 mb-4">
          ReadPDF is only responsible for providing Products upon successful
          payment completion.
        </p>
        <p className="text-gray-700 mb-4">
          ReadPDF reserves the right to change its fees, charges, and prices at
          any time. We will notify you with at least 30 days notice before such
          a change.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">5. Cancellation</h2>
        <p className="text-gray-700 mb-4">
          If you purchased a subscription through the Apple iTunes Store, or the
          Apple App Store, you can cancel your subscription by clicking on “App
          Store”, then “Profile”, then “Manage Subscriptions”.
        </p>
        <p className="text-gray-700 mb-4">
          If you purchased a subscription through the Google Play store, you can
          cancel your subscription by opening the Google play app, navigate to
          Subscriptions, then cancel the automatic renewal.
        </p>
        <p className="text-gray-700 mb-4">
          Either of these processes may change. Please refer to the Apple or
          Google store processes at the time of cancellation.
        </p>
        <p className="text-gray-700 mb-4">
          In the event of a breach of terms, fraud, or other issue, ReadPDF
          reserves the right to terminate your account. In the event of a
          termination, ReadPDF will notify you by email.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">6. License Agreement</h2>
        <p className="text-gray-700 mb-4">
          ReadPDF hereby issues a limited, revocable, non-exclusive license for
          you to download, stream, read, and non-commercially and personally use
          ReadPDF’s products.
        </p>
        <p className="text-gray-700 mb-4">
          The Product may contain material with copyrights that belong to
          ReadPDF. ReadPDF retains all rights to those copyrights. The right to
          download and stream the content created by ReadPDF is licensed to you,
          not sold to you, and may be revoked at any time.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">7. Maintenance</h2>
        <p className="text-gray-700 mb-4">
          ReadPDF is solely responsible for the maintenance of its products.
          Both you and ReadPDF acknowledge that neither Apple nor Google has any
          obligation whatsoever to furnish any maintenance and support services
          with respect to the products.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">8. Product Claims</h2>
        <p className="text-gray-700 mb-4">
          Both you and ReadPDF acknowledge that ReadPDF, not Apple, is
          responsible for addressing any issues arising out of product claims.
          These include, but are not limited to:
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>(i) product liability claims;</li>
          <li>
            (ii) any claim that the Licensed Application fails to conform to any
            applicable legal or regulatory requirement; and
          </li>
          <li>
            (iii) claims arising under consumer protection, privacy, or similar
            legislation, including in connection with the Product's use of the
            HealthKit and HomeKit frameworks. This EULA does not limit ReadPDF's
            liability to you beyond what is permitted by applicable law.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          9. Intellectual Property Claims
        </h2>
        <p className="text-gray-700 mb-4">
          ReadPDF acknowledges that, in the event of any third party claim that
          the Product or your possession and use of that Product infringes that
          third party’s intellectual property rights, ReadPDF or the end user
          (not Apple or Google) will be responsible for the investigation,
          defense, settlement, and discharge of any such intellectual property
          infringement claim.
        </p>
        <p className="text-gray-700 mb-4">
          You agree to ONLY use The Products with material you’ve lawfully
          acquired. This can include through purchase, through licensing
          agreements, or if the material is free of copyright.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          10. Digital Millennium Copyright Act (“DMCA”) Notice
        </h2>
        <p className="text-gray-700 mb-4">
          ReadPDF is committed to complying with all applicable laws, including
          copyright laws. Our users are required to comply with these same laws.
        </p>
        <p className="text-gray-700 mb-4">
          You may not use, store, or share any material in a way that
          constitutes an infringement on intellectual property rights, including
          copyrights. Under the Digital Millennium Copyright Act of 1998 (the
          “DMCA”), owners of copyrights may report believed infringements of
          their exclusive rights. ReadPDF.io will immediately investigate, and
          if necessary take action on these reports.
        </p>
        <p className="text-gray-700 mb-4">
          <strong>DMCA REPEAT INFRINGER POLICY:</strong> Users who are
          determined by ReadPDF to be repeat infringers of copyright or other
          intellectual property rights will have their accounts terminated. The
          number and nature of these infringements will be taken into account,
          and ReadPDF reserves the right to terminate accounts based on a single
          infringement in severe cases. A user may be considered a repeat
          infringer if they are repeatedly notified of infringing activity
          and/or have had material or activity removed from The Products due to
          copyright infringement.
        </p>
        <p className="text-gray-700 mb-4">
          If you believe your intellectual property rights are being infringed
          upon, we encourage you to contact us immediately:
        </p>
        <p className="text-gray-700 mb-4">Email: support@readpdf.io</p>
        <p className="text-gray-700 mb-4">
          Contact: ReadPDF.io, Petko Bozhinov
        </p>
        <p className="text-gray-700 mb-4">Address: Sofia, Bulgaria</p>
        <p className="text-gray-700 mb-4">
          Your notice of copyright infringement must include the following
          information:
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>
            A physical or electronic signature of a person authorized to act on
            behalf of the owner of an exclusive right that is allegedly
            infringed.
          </li>
          <li>
            Identification of the copyrighted work or works claimed to have been
            infringed.
          </li>
          <li>
            Identification of the material that is claimed to be infringing and
            its location on the ReadPDF Website or Apps.
          </li>
          <li>Your email, address, and telephone number.</li>
          <li>
            A statement that you have a good faith belief that the identified
            material is not authorized by you, the copyright owner, or by law.
          </li>
          <li>
            A statement by you, under penalty of perjury, that the information
            in your notice is accurate and that you are the copyright owner or
            authorized to act on behalf of the copyright owner.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">11. Arbitration</h2>
        <p className="text-gray-700 mb-4">
          These Terms shall be governed by the laws of the Republic of Bulgaria,
          without regard to conflict of law provisions.
        </p>
        <p className="text-gray-700 mb-4">
          Any dispute that arises out of this agreement, or in connection with
          ReadPDF, or the usage of the Products, will be resolved through
          binding arbitration in Bulgaria.
        </p>
        <p className="text-gray-700 mb-4">
          By agreeing to these terms, you hereby waive your right to go to court
          and have a trial in front of a jury or judge. You are hereby electing,
          and agreeing to, having any claims resolved by arbitration.
        </p>
        <p className="text-gray-700 mb-4">
          As long as permitted until applicable law, you hereby waive your right
          to file or join a class action lawsuit. You hereby agree to only bring
          claims as an individual party, not as a class member in a class action
          lawsuit.
        </p>
        <p className="text-gray-700 mb-4">
          You may opt out of this arbitration agreement. If you do so, you must
          notify ReadPDF within 30 days after becoming subject to this
          agreement. Send your opt-out notice to support@readpdf.io.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">12. Severability</h2>
        <p className="text-gray-700 mb-4">
          If any portion of this contract is deemed unenforceable, then that
          provision shall be severed from this contract. The remainder of the
          contract shall continue to be valid and enforceable.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">13. Compliance</h2>
        <p className="text-gray-700 mb-4">You represent and warrant that:</p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>
            (i) You are not listed on any EU list of prohibited or restricted
            parties.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">14. Third Party Terms</h2>
        <p className="text-gray-700 mb-4">
          You must comply with any applicable third party terms of agreement
          when using ReadPDF.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          15. Third Party Beneficiary
        </h2>
        <p className="text-gray-700 mb-4">
          You and ReadPDF both acknowledge and agree that Apple, and Apple’s
          subsidiaries, are third party beneficiaries of the EULA, and that,
          upon your acceptance of the terms and conditions of the EULA, Apple
          will have the right (and will be deemed to have accepted the right) to
          enforce the EULA against the End-User as a third party beneficiary
          thereof.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">
          16. User-Submitted Content
        </h2>
        <p className="text-gray-700 mb-4">
          16.1 Our Services may permit you to submit various materials to us,
          which can include, but are not limited to, uploading photos to your
          profile, uploading text, video, audio, PDFs, or imagery. In these
          Terms, we refer to these submissions as "User-Submitted Content,"
          encompassing any form of material that you make publicly available to
          us, which can range from text, files, images, photographs, video,
          audio, and artistic or literary creations.
        </p>
        <p className="text-gray-700 mb-4">
          16.2 This Section 16 outlines the rights and obligations that you and
          we hold concerning User-Submitted Content. Your agreement to submit or
          review User-Submitted Content implies your acceptance of these Terms.
          Should you disagree with these Terms, please refrain from submitting
          or reviewing User-Submitted Content.
        </p>
        <p className="text-gray-700 mb-4">
          16.3 We do not perform systematic assessments of User-Submitted
          Content provided by you or other users. The content of User-Submitted
          Material is your responsibility and not ours. We disclaim all
          warranties and representations, whether explicit or implicit,
          regarding User-Submitted Material, including its legality or
          precision.
        </p>
        <p className="text-gray-700 mb-4">
          16.4 We hold the exclusive right, at our sole discretion, to reject,
          edit, or remove your User-Submitted Content, or to limit, suspend, or
          terminate your access to any or all parts of the Services. This could
          occur if User-Submitted Content contravenes Section 7, with or without
          prior notice to you.
        </p>
        <p className="text-gray-700 mb-4">
          16.5 We may link User-Submitted Content or parts thereof to other
          material, including content submitted by other users or produced by us
          or other third parties. We may employ User-Submitted Content for our
          internal business objectives, such as analyzing trends or categories,
          or to promote, market, or advertise our services. You understand and
          accept that we may indirectly derive commercial gain from your
          User-Submitted Content.
        </p>
        <p className="text-gray-700 mb-4">
          16.6 Every instance of your submission of User-Submitted Content is
          governed by the following warranties and representations:
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>
            (a) You possess the lawfully acquired rights of your User-Submitted
            Content or are authorized to submit it. Your submission will not
            violate any rights of third parties, encompassing intellectual
            property rights (e.g., copyright or trademarks), privacy or
            publicity rights, confidentiality rights, or contractual rights. By
            uploading your content to ReadPDF, you represent and warrant that
            you have the necessary intellectual property rights.
          </li>
          <li>
            (b) Your User-Submitted Content is not illegal, obscene, defamatory,
            threatening, pornographic, harassing, hateful, racially or
            ethnically offensive. It doesn't incite illegal activities, provoke
            civil liabilities, contravene any laws, or is otherwise regarded as
            unsuitable.
          </li>
          <li>
            (c) You won't engage in illegal or harmful activities, such as
            fraud, spamming, sending harmful files, copyright infringement,
            patent infringement, theft of trade secrets, or attempting to
            impersonate another user.
          </li>
          <li>
            (d) You won't use automated systems, such as scripts, to modify our
            content.
          </li>
        </ul>
        <p className="text-gray-700 mb-4">
          16.7 We reserve the right to disclose your identity to any third party
          claiming that their rights have been infringed by your User-Submitted
          Content.
        </p>
        <p className="text-gray-700 mb-4">
          16.8 The Products provided by ReadPDF are intended for SINGLE-USER
          non-commercial usage ONLY. Dissemination of content to others
          constitutes a breach of terms leading to account termination. You bear
          the complete legal accountability should you share any materials with
          others. ReadPDF retains the right to actively monitor for any unusual
          activity on your account, and respond as deemed appropriate, including
          but not limited to account termination.
        </p>
        <p className="text-gray-700 mb-4">
          16.9 ReadPDF does not allow exports of converted audios, and has taken
          deliberate technical measures to prevent the exporting of such audios.
          Circumvention of these measures is strictly prohibited and grounds for
          account termination.
        </p>
      </section>

      <section>
        <h2 className="text-xl font-semibold mb-2">17. Contact Information</h2>
        <p className="text-gray-700 mb-4">
          Questions can be directed at the following address:
        </p>
        <p className="text-gray-700 mb-4">Email: support@readpdf.io</p>
        <p className="text-gray-700 mb-4">Address: Sofia, Bulgaria</p>
      </section>
    </div>
  );
};

export default TermsAndConditionsPage;
